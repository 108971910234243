import { Component, Provide, Vue } from "vue-property-decorator";
import Product from "@/components/Product.vue";
import { GetCommodityReq } from "@/interface/res";
import { GetCommodityApi, GetCommodityApiPc } from "@/network/modules";
import { ProductMenu, ProductList } from "@/interface/pagas";
import {
  ClickCollectionApi,
  GetByDictTypeListApi,
} from "@/network/modules/common";
import {
  ClickCollectionReq,
  GetByDictTypeListReq,
} from "@/interface/res/common";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { gsap, TweenMax } from "gsap";

const delay = (function () {
  let timer = 0;
  return function (callback: any, ms: any) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
@Component({
  components: {
    Product,
  },
  computed: {},
})
export default class EmbroideryHome extends Vue {
  @Provide() condition: GetCommodityReq = {
    commoditySeries: 1,
    pageNum: 1,
    pageSize: 8,
    lastSort: 0,
    // commodityType:-1,
    // searchName:'',
  };
  @Provide() menuListIndex: number = 0;
  @Provide() menuList: Array<ProductMenu> = [
    { dictValue: "-1", dictLabel: "全部" },
  ];
  @Provide() productList: Array<ProductList> = [];
  @Provide() productListTotal: number = -1;
  @Provide() totalFlgs: boolean = true;
  @Provide() loading: boolean = false;
  @Provide() moreFlg: boolean = false;
  @Provide() flg: boolean = true;

  mounted() {
    
  
    
    new TweenMax(".box1", 1, {
      x: 120,
      delay: 1,
      // ease: 'Bounce.easeOut',
      alpha: 1,
    });
    new TweenMax(".box2", 2, {
      x: 120,
      delay: 1,
      // ease: 'Bounce.easeOut',
      alpha: 1,
    });
    new TweenMax(".box3", 3, {
      x: 120,
      delay: 1,
      // ease: 'Bounce.easeOut',
      alpha: 1,
    });
    new TweenMax(".banner-img", 3, {
      x: -115,
      alpha: 1,
      delay: 1,
    });
    // var myTween = TweenMax.to(".box", 1, {
    //   x: function(index, target) {
    //     console.log(index, target);
    //     return (index + 1) *50 // 100, 200, 300
    //   }
    // })
    const _this: any = this;
    let proxy = { skew: 0 },
      skewSetter = gsap.quickSetter(
        ".product-content_box__item",
        "skewY",
        "deg"
      ), // fast
      clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.

    ScrollTrigger.create({
      onUpdate: (self) => {
        let skew = clamp(self.getVelocity() / -300);
        // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
        if (Math.abs(skew) > Math.abs(proxy.skew)) {
          proxy.skew = skew;
          gsap.to(proxy, {
            skew: 0,
            duration: 0.8,
            ease: "power3",
            overwrite: true,
            onUpdate: () => skewSetter(proxy.skew),
          });
        }
      },
    });

    // make the right edge "stick" to the scroll bar. force3D: true improves performance
    gsap.set(".product-content_box__item", {
      transformOrigin: "right center",
      force3D: true,
    });
  }
  created() {
    const _this: any = this;
    _this.GetByDictTypeListFn();
    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      // console.log(scrollTop+'+'+windowHeight+'='+(scrollHeight-364));
      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight - 364) {
        //写后台加载数据的函数
        delay(() => {
          console.log(_this.moreFlg, _this.flg);
          if (_this.moreFlg && _this.flg) {
            console.log('---------B');
            //执行部分
            _this.loadingMoreFn();
          }
        }, 100);
        // console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
      }
    };
  }

  /**
   * @Author HS
   * @Date 2021/7/8 11:31 上午
   * @Description: 产品类型
   * @Params: null
   * @Return: null
   */
  async GetByDictTypeListFn() {
    const _this: any = this;
    _this.loading = true;
    let params: GetByDictTypeListReq = {
      dictType: "commodity_type_cxjj",
    };
    const res = await GetByDictTypeListApi(params);
    const { data, code, msg } = res;
    if (code != 200) {
      _this.$message.error(msg);
      return;
    }
    _this.menuList = [..._this.menuList, ...data];
    _this.GetByPageFn();
  }

  /**
   * @Author HS
   * @Date 2021/7/7 6:26 下午
   * @Description: 产品列表
   * @Params: null
   * @Return: null
   */
  async GetByPageFn() {
    const _this: any = this;
    _this.flg = false;
    if (_this.condition.pageNum == 1) {
      _this.productListTotal = -1;
      _this.productList = [];
    }
    let params: GetCommodityReq = _this.condition;

    const res = await GetCommodityApiPc(params);
    let a = setTimeout(() => {
      _this.loading = false;
      clearTimeout(a);
    }, 500);
    const { data, code, total, msg } = res;

    if (code != 200) {
      _this.$message.error(msg);
      return;
    }
    _this.flg = true;
    _this.productList = [..._this.productList, ...data];
    _this.totalFlgs = data.length < total ? true : false;
    if (data.length > 0) {
      // _this.condition.lastSort = data[data.length - 1].sort;
      _this.condition.lastSort = _this.productList.length;
      _this.moreFlg = true;
    } else {
      _this.moreFlg = false;
    }
    // _this.productListTotal = total

    if (data.length != 0 && _this.condition.pageNum > 1) {
      _this.productListTotal = total;
    }
    if (data.length > 0 && _this.condition.pageNum == 1) {
      _this.productListTotal = total;
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/7 6:37 下午
   * @Description: 类型切换
   * @Params: { number ： id - true } [id]
   * @Return: null
   */
  menuChangeFn(index: number) {
    const _this: any = this;
    _this.menuListIndex = index;
    _this.condition.pageNum = 1;
    _this.condition.lastSort = 0;
    _this.productListTotal = -1;
    _this.productList = [];
    if (index == 0) {
      delete _this.condition.commodityType;
      _this.GetByPageFn();
      return;
    }
    _this.condition.commodityType = _this.menuList[index].dictValue;
    _this.GetByPageFn();
  }

  /**
   * @Author HS
   * @Date 2021/7/7 6:37 下午
   * @Description: 点击查看更多
   * @Params: null
   * @Return: null
   */
  loadingMoreFn() {
    const _this: any = this;
    if (_this.moreFlg && _this.flg) {
      _this.condition.pageNum++;
      //执行部分
      _this.GetByPageFn();
    } else {
      delay(() => {
        _this.$message.error("正在加载中，请稍等...");
      }, 500);
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/7 7:00 下午
   * @Description: 点击收藏
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  async ClickCollectionFn(nIndex: number) {
    const _this: any = this;
    if (_this.$base.isLogin()) {
      const path = _this.$base.routeLinkSplice(
        _this.$LoginConfig.login.path,
        "embroideryHome"
      );
      _this.$base.goRouter(path);
      return;
    }

    const item = _this.productList.filter(
      (item: any, index: number) => index == nIndex
    )[0];
    let params: ClickCollectionReq = {
      commodityId: item.commodityId,
    };
    const res = await ClickCollectionApi(params);
    const { code, data, msg } = res;
    if (code != 200) {
      _this.$message.error(msg);
      return;
    }
    item.isCollection = data;
    _this.productList[nIndex].isCollection = item.isCollection;
  }

  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: { number ： id - true } [id]
   * @Return: null
   */
  goDetails(id: number) {
    const _this: any = this;
    const link = _this.$base.routeLinkSplice(
      _this.$IndexConfig.embroideryHomeDetail.path,
      "type=1&i=" + id.toString()
    );
    console.log("link:", link);
    window.open(link, "_blank");
  }
}
